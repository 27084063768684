import CarouselBase from 'nuka-carousel';
import React from 'react';

import styles from './jobs-item.module.css';

export const Job = props => {
  return (
    <a
      href={props.link}
      className={styles.itemWrapper}
      style={{ background: props.color }}
    >
      <div className={styles.jobDate}>{props.date}</div>
      <div className={styles.jobTitle}>{props.title}</div>
      <div className={styles.mealDescription}>{props.description} </div>
    </a>
  )
}

const renderJobs = () => [
  <Job
    key="1"
    title="Sales Manager"
    description="NutritionPro is one of the fastest-growing Foodtech startups. (500%+ year-on-year growth)."
    date="10.06.2022"
    color="#EAEFF7"
    link="/jobs/sales"
  />
]

const Jobs = () => {
  // const scroll = useSmoothScroll()

  return (
    <div className={styles.content}>
      {/* <Title>Jak vypadá den s NutritionPro</Title> */}

      <div className={styles.meals}>{renderJobs()}</div>
      {/* <CarouselBase
        className={styles.carousel}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={({ previousSlide }) => (
          <button type="button" onClick={previousSlide}>
            prev
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button type="button" onClick={nextSlide}>
            next
          </button>
        )}
      >
        {renderJobs()}
      </CarouselBase> */}
    </div>
  )
}

export default Jobs
